import React, { Component } from 'react'
import Link from '../utils/link'
import Newsletter from './newsletter'

import { Logo } from './icons'
import Instagram from '../assets/images/instagram.svg'
import LinkedIn from '../assets/images/linkedin.svg'
import Facebook from '../assets/images/facebook.svg'
import YouTube from '../assets/images/youtube.svg'

class Footer extends Component {
  
  render() {

    let { footer, social } = this.props.data.wp.siteSettings.acf

    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__mobile'>
            <Link to='/' title='Hodges' className='footer__logo'>
              <Logo color={'#FFFFFF'} />
            </Link>
            <ul>
              <li>
                <Link to={social.instagram}>
                  <img src={Instagram} alt='Instagram' />
                </Link>
              </li>
              <li>
                <Link to={social.linkedin}>
                  <img src={LinkedIn} alt='LinkedIn' />
                </Link>
              </li>
              <li>
                <Link to={social.facebook}>
                  <img src={Facebook} alt='Facebook' />
                </Link>
              </li>
              <li>
                <Link to={social.youtube}>
                  <img src={YouTube} alt='YouTube' />
                </Link>
              </li>
            </ul>
          </div>
          
          <div className='footer__cols'>
            <div className='footer__col'>
              <h4>{footer.buy.title}</h4>
              <ul>
                {footer.buy.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.link}>{el.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='footer__col'>
              <h4>{footer.sell.title}</h4>
              <ul>
                {footer.sell.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.link}>{el.text}</Link>
                  </li>
                ))}
              </ul>
              <h4>{footer.invest.title}</h4>
              <ul>
                {footer.invest.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.link}>{el.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='footer__col'>
              <h4>{footer.manage.title}</h4>
              <ul>
                {footer.manage.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.link}>{el.text}</Link>
                  </li>
                ))}
              </ul>
              <h4>{footer.rent.title}</h4>
              <ul>
                {footer.rent.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.link}>{el.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='footer__col'>
              <h4>{footer.company.title}</h4>
              <ul>
                {footer.company.menu.map((el, i) => (
                  <li key={i}>
                    <Link to={el.link}>{el.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className='footer__col'>
              <h4>{footer.newsletter}</h4>
              <Newsletter />
              <div className='footer__social'>
                <div className='footer__button'>
                  <span>{footer.socialTitle}</span>
                  <Link to='/' className='btn'>Start your search</Link>
                </div>
                <ul>
                  <li>
                    <Link to={social.instagram}>
                      <img src={Instagram} alt='Instagram' />
                    </Link>
                  </li>
                  <li>
                    <Link to={social.linkedin}>
                      <img src={LinkedIn} alt='LinkedIn' />
                    </Link>
                  </li>
                  <li>
                    <Link to={social.facebook}>
                      <img src={Facebook} alt='Facebook' />
                    </Link>
                  </li>
                  <li>
                    <Link to={social.youtube}>
                      <img src={YouTube} alt='YouTube' />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footer__credits'>
            <ul>
              {footer.policy.map((el, i) => (
                <li key={i}>
                  <Link to={el.link}>{el.text}</Link>
                </li>
              ))}
            </ul>
            <ul>
              <li>© Hodges All rights reserved {new Date().getFullYear()}</li>
              <li></li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer

module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hodges","short_name":"hodges","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b8cd69a50cfd962b9865fa9c8c19abd1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://hodges.codedrips.com/graphql","searchAndReplace":[{"search":"https://hodges.codedrips.com","replace":"https://hodges-1beca.kxcdn.com"},{"search":"https://www.hodges.com.au/wp-content/uploads","replace":"https://hodges-1beca.kxcdn.com/wp-content/uploads"}],"html":{"useGatsbyImage":false,"createStaticFiles":false,"generateWebpImages":false,"generateAvifImages":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"placeholderType":"dominantColor"},"schema":{"requestConcurrency":50,"previewRequestConcurrency":50,"perPage":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"develop":{"nodeUpdateInterval":300000,"hardCacheMediaFiles":true,"hardCacheData":false},"type":{"MediaItem":{"createFileNodes":false,"localFile":{"excludeByMimeTypes":["video/mp4","image/gif"],"maxFileSizeBytes":1,"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"Property":{"limit":null},"Rental":{"limit":null},"Post":{"limit":null},"User":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KZZJHLS","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"628008374535864"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

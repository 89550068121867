import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      fragment TeamCard on WpTeam {
        title
        slug
        teamData {
          agent_id
        }
        acf {
          firstName
          lastName
          thumbnailImage {
            sourceUrl
          }
          jobTitle
          office {
            ... on WpOffice {
              id
              title
            }
          }
          email
          mobile
        }
      }
      fragment RentalCard on WpRental {
        databaseId
        id
        slug
        title
        images {
          img_1200x
        }
        media {
          content_type
          url
        }
        inspections {
          inspection_date
          inspection_start_time
          inspection_end_time
        }
        meta {
          listing_id
          bathrooms
          bedrooms
          parking_total
          category
          sold_details_sold_date
          sold_details_sold_price
          sold_details_sold_price_display
          sale_type
          address_hide
          address_street_name
          address_street_number
          address_street_type
          address_suburb
          address_lot_number
          auction_date
          auction_time
          publish_date
          agent_id_1
          agent_id_2
          category
          rent_display
          rent
          date_available
        }
      }
      fragment PropertyCard on WpProperty {
        databaseId
        id
        slug
        title
        images {
          img_1200x
        }
        media {
          content_type
          url
        }
        inspections {
          inspection_date
          inspection_start_time
          inspection_end_time
        }
        meta {
          listing_id
          bathrooms
          bedrooms
          parking_total
          category
          sold_details_sold_date
          sold_details_sold_price
          sold_details_sold_price_display
          sale_type
          address_hide
          address_street_name
          address_street_number
          address_street_type
          address_suburb
          address_lot_number
          auction_date
          auction_time
          publish_date
          agent_id_1
          agent_id_2
          category
          rent_display
          rent
          date_available
        }
      }
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        wp {
          siteSettings {
            acf {
              offCanvasImage: offCanvsImage {
                sourceUrl
              }
              nav {
                title
                menu {
                  text
                  link
                }
              }
              popup {
                title
                content
                image {
                  sourceUrl
                }
              }
              footer {
                buy {
                  title
                  menu {
                    text
                    link
                  }
                }
                sell {
                  title
                  menu {
                    text
                    link
                  }
                }
                invest {
                  title
                  menu {
                    text
                    link
                  }
                }
                manage {
                  title
                  menu {
                    text
                    link
                  }
                }
                rent {
                  title
                  menu {
                    text
                    link
                  }
                }
                company {
                  title
                  menu {
                    text
                    link
                  }
                }
                newsletter
                socialTitle
                policy {
                  text
                  link
                }
              }
              social {
                facebook
                instagram
                linkedin
                youtube
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <>
          <Header data={data} />
          <main>{ children }</main>
          <Footer data={data} />
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

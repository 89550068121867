import React, { Component } from 'react'

import Arrow from '../assets/images/arrow-right.svg'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class NewsletterForm extends Component {

  state = {
    email: '',
    result: {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // 2. via `async/await`
  handleSubmit = async (e) => {
    e.preventDefault();

    const data = encode({ 
      email: this.state.email,
      subscribe: true,
    })
    await fetch("https://hooks.zapier.com/hooks/catch/2181815/bwt62w0/?"+data)

    // I recommend setting `result` to React state
    // but you can do whatever you want
    this.setState({
      result: 'success',
    })
  }

  render() {
    let props = {
      ref: "form",
      name: "newsletter",
      className: "newsletter",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    }

    if (this.state.result === "success")
      return (
        <p className="contact__success" style={{color: "white"}}>
          Thanks for subscribing.
        </p>
      )

    return (
      <form {...props}>
        <div className="newsletter__row">
          <input
            type="email"
            name="email"
            placeholder="Enter email address"
            onChange={this.handleChange}
            required
          />
          <button type="submit" className="btn">
            <img src={Arrow} alt='Submit' />
          </button>
        </div>
      </form>
    )
  }
}

export default NewsletterForm
import React, { Component } from 'react'
import Link from '../utils/link'

import { Logo } from './icons'
import Instagram from '../assets/images/instagram.svg'
import LinkedIn from '../assets/images/linkedin.svg'
import Facebook from '../assets/images/facebook.svg'
import YouTube from '../assets/images/youtube.svg'

class Header extends Component {

  state = {
    offCanvas: false,
    subMenu: [false, false, false, false, false, false],
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  toggleScrolled = () => {
    this.setState({ scrolled: !this.state.scrolled })
  }

  onScroll = (e) => {
    let scrolled = window.scrollY > 1
    if (scrolled !== this.state.scrolled) {
      requestAnimationFrame(this.toggleScrolled)
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ 
      offCanvas: false ,
      subMenu: false,
    })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleSubMenu = (key) => {
    this.setState({ subMenu: {[key]: !this.state.subMenu[key]} })
  }

  render() {

    let { nav, offCanvasImage, social } = this.props.data.wp.siteSettings.acf
    let { offCanvas, subMenu, scrolled } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeclassname: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' title='Hodges' className='header__logo' {...props}>
              <Logo color={'#FFFFFF'} />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li><Link to='/properties-for-sale/' {...props}>Buy</Link></li>
                <li><Link to='/sell-with-hodges/' {...props}>Sell</Link></li>
                <li><Link to='/properties-for-lease/' {...props}>Lease</Link></li>
                <li><Link to='/invest-with-hodges/' {...props}>Invest</Link></li>
                <li className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                  <button onClick={this._toggleOffCanvas}>
                    <span className='lines'></span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <img className='off-canvas__bg' loading='lazy' src={offCanvasImage?.sourceUrl} alt={'Hodges Real Estate'} />
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                { nav?.map((el, i) => (
                  <li key={i}>
                    <button 
                      type='button'
                      onClick={() => this._toggleSubMenu(i)}
                      className={`${subMenu[i] ? 'active' : ''}`}
                    >
                      <span>{el.title}</span>
                      <svg width="13px" height="8px" viewBox="0 0 13 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g transform="translate(-428.000000, -271.000000)">
                            <g transform="translate(431.048413, 268.500000)">
                              <g transform="translate(3.299430, 6.347842) rotate(90.000000) translate(-3.299430, -6.347842)">
                                <g transform="translate(3.299430, 6.347842) scale(-1, 1) rotate(90.000000) translate(-3.299430, -6.347842) translate(-3.048413, 3.048413)">
                                  <polygon fill="#FFFFFF" fillRule="nonzero" points="6.34776815 6.59885939 6.74741738 6.24104135 12.695685 0.887600495 11.8963865 0 6.3478425 4.99565256 0.799298462 0 0 0.887600495 5.94826762 6.24104135"></polygon>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                    { el.menu && 
                      <ul className={`off-canvas__sub-menu ${subMenu[i] ? 'active' : ''}`}>
                        { el.menu.map((sub, s) => (
                          <li key={s}>
                            <Link to={sub.link} {...props}>{sub.text}</Link>
                          </li>
                        )) }
                      </ul>
                    }
                  </li>
                )) }
              </ul>
            </nav>
            <ul className='off-canvas__terms'>
              <li><Link to='/privacy-policy/'>Privacy Policy</Link></li>
              <li className='social'>
                <Link to={social.instagram}>
                  <img src={Instagram} alt='Instagram' />
                </Link>
              </li>
              <li className='social'>
                <Link to={social.linkedin}>
                  <img src={LinkedIn} alt='LinkedIn' />
                </Link>
              </li>
              <li className='social'>
                <Link to={social.facebook}>
                  <img src={Facebook} alt='Facebook' />
                </Link>
              </li>
              <li className='social'>
                <Link to={social.youtube}>
                  <img src={YouTube} alt='YouTube' />
                </Link>
              </li>
            </ul>
            <ul className='off-canvas__social'>
              <li>
                <Link to={social.instagram}>
                  <img src={Instagram} alt='Instagram' />
                </Link>
              </li>
              <li>
                <Link to={social.linkedin}>
                  <img src={LinkedIn} alt='LinkedIn' />
                </Link>
              </li>
              <li>
                <Link to={social.facebook}>
                  <img src={Facebook} alt='Facebook' />
                </Link>
              </li>
              <li>
                <Link to={social.youtube}>
                  <img src={YouTube} alt='YouTube' />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </>
    )
  }
}

export default Header
